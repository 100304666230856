.table-body {
  padding: 3rem 10rem;
}
.chargeButton {
  color: #ffffff;
  padding-inline: 20px;
  width: auto;
}
.address-text {
  font-size: 22px;
}
.comn-title {
  font-size: 36px;
  font-weight: bold;
}
.free-course-text {
  display: table;
  padding: 10px 20px;
}
.free-course-text {
  display: table;
  padding: 6px 20px;
  background-color: #e79110;
  color: #ffffff;
  border-radius: 40px;
  margin-bottom: 10px;
}
@media screen and (width <= 767px) {
  .table-body {
    padding: 20px;
  }
  .logo {
    max-width: 200px;
    margin-bottom: 10px;
  }
  .address-text {
    font-size: 16px;
  }
  .comn-title {
    font-size: 26px;
    margin-block: 20px;
  }
}
